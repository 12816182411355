






















import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";

@Component
export default class PersonalInterestsForm extends Vue {
  @Model("input", { type: Array }) readonly userTags!: Array<number>;

  @Emit()
  input() {
    return this.localUserTags;
  }

  localUserTags!: Array<number>;
  backupUserTags!: Array<number>;
  tags: null | object = null;

  constructor() {
    super();

    this.localUserTags = this.backupUserTags = [...this.userTags];
    this.fetchTags();
  }

  @Watch("localUserTags")
  onLocalUserTagsChanged() {
    this.input();
  }

  fetchTags() {
    this.$store.dispatch("tags/fetch").then(({ data }) => (this.tags = data.data));
  }
}
